// -*- mode: css -*-

.hljs-comment, .hljs-quote {
    color: $c999;
}

.hljs-keyword, .hljs-selector-tag, .hljs-subst {
    color: var(--text-color);
    font-weight: 700
}

.hljs-number, .hljs-literal, .hljs-variable, .hljs-template-variable, .hljs-tag .hljs-attr {
    color: teal
}

.hljs-string, .hljs-doctag {
    color: #d14
}

.hljs-title, .hljs-section, .hljs-selector-id {
    color: #900;
    font-weight: 700
}

.hljs-subst {
    font-weight: 400
}

.hljs-type, .hljs-class .hljs-title {
    color: #458;
    font-weight: 700
}

.hljs-tag, .hljs-name, .hljs-attribute {
    color: navy;
    font-weight: 400
}

.hljs-regexp, .hljs-link {
    color: #009926
}

.hljs-symbol, .hljs-bullet {
    color: #990073
}

.hljs-built_in, .hljs-builtin-name {
    color: #0086b3
}

.hljs-meta {
    color: $c999;
    font-weight: 700
}

.hljs-deletion {
    background: #fdd
}

.hljs-addition {
    background: #dfd
}

.hljs-emphasis {
    font-style: italic
}

.hljs-strong {
    font-weight: 700
}

// -*- mode: css -*-

font-src(filename, type, ext)
  url('/images/fonts/' + filename + '-' + type + '.' + ext) format(ext)

woff-src(filename, type, has-woff2)
  font-display: block;
  if has-woff2 then
    src: font-src(filename, type, 'woff2'), font-src(filename, type, 'woff')
  else
    src: font-src(filename, type, 'woff')

self-hosted-font-face(name, woff2 = false, fileName = name, regularFile = 'Regular', italicFile = 'Italic', boldFile = 'Bold', boldItalicFile = 'BoldItalic')
    @font-face {
        font-family: name + " subset";
        woff-src(fileName, 'Regular.subset', true)
        font-display: swap;
    }
    @font-face {
        font-family: name;
        woff-src(fileName, regularFile, woff2)
        /* font-display: swap; */
        /* лучше все-таки подождать, пока загрузится */
    }
    @font-face {
        font-family: name;
        font-style: italic;
        woff-src(fileName, italicFile, woff2)
    }
    @font-face {
        font-family: name;
        font-style: normal;
        font-weight: bold;
        woff-src(fileName, boldFile, woff2)
    }
    @font-face {
        font-family: name;
        font-style: italic;
        font-weight: bold;
        woff-src(fileName, boldItalicFile, woff2)
    }

@font-face {
    font-family: "PT Mono";
    woff-src(PTMono, 'Regular', true)
}
@font-face {
    font-family: "PT Mono";
    font-style: normal;
    font-weight: bold;
    woff-src(PTMono, 'Bold', true)
}
@font-face {
    font-family: "Fira Mono";
    woff-src(FiraMono, 'Regular', true)
}
@font-face {
    font-family: "Fira Mono";
    font-style: normal;
    font-weight: bold;
    woff-src(FiraMono, 'Bold', true)
}

/*
TODO: добавить правильные unicode-ranges?
ttx -t cmap -d . IBMPlexDevanagari-Regular.otf
https://developer.apple.com/fonts/TrueType-Reference-Manual/RM06/Chap6cmap.html

cmap_format_4 & cmap_format_12
platformID="0" (Unicode)
platEncID="0" (Default Semantics) (but possibly "3" and "4"...??)
*/

/* @font-face { */
/*     font-family: IBMPlexDevanagari; */
/*     unicode-range: U+0900-097F; */
/*     woff-src(IBMPlexDevanagari, 'Regular', true); */
/* } */
/* @font-face { */
/*     font-family: IBMPlexDevanagari; */
/*     unicode-range: U+0900-097F; */
/*     font-style: normal; */
/*     font-weight: bold; */
/*     woff-src(IBMPlexDevanagari, 'Bold', true) */
/* } */

/* @font-face { */
/*     font-family: IBMPlexSansHebrew; */
/*     unicode-range: U+0590-05FF, U+FB1D-FB4F; */
/*     woff-src(IBMPlexSansHebrew, 'Regular', true) */
/* } */
/* @font-face { */
/*     font-family: IBMPlexSansHebrew; */
/*     unicode-range: U+0590-05FF, U+FB1D-FB4F; */
/*     font-style: normal; */
/*     font-weight: bold; */
/*     woff-src(IBMPlexSansHebrew, 'Bold', true) */
/* } */

/* @font-face { */
/*     font-family: IBMPlexThai; */
/*     unicode-range: U+0E01-0E3A, U+0E3F-0E5B; */
/*     woff-src(IBMPlexThai, 'Regular', true) */
/* } */
/* @font-face { */
/*     font-family: IBMPlexThai; */
/*     unicode-range: U+0E01-0E3A, U+0E3F-0E5B; */
/*     font-style: normal; */
/*     font-weight: bold; */
/*     woff-src(IBMPlexThai, 'Bold', true) */
/* } */

/* self-hosted-font-face(SourceSansPro, true) */
/* self-hosted-font-face(SourceCodePro, true) */
/* self-hosted-font-face(SourceSerifPro, true) */
/* self-hosted-font-face(IBMPlexSans, true) */
/* self-hosted-font-face(IBMPlexSerif, true) */
/* self-hosted-font-face(IBMPlexMono) */
/* self-hosted-font-face(CharterFree, true) */

self-hosted-font-face("PT Sans", true, PTSans)
self-hosted-font-face("PT Serif", true, PTSerif)
self-hosted-font-face("FiraGO", true)


/* Два !! вместо одного, чтобы прошло через stylus и postcss.
   Stylus убирает первый '!' и как это отключить я не нашел */
/*!!
 This CSS resource incorporates links to font software which is the
 valuable copyrighted property of Monotype and/or its suppliers. You
 may not attempt to copy, install, redistribute, convert, modify or
 reverse engineer this font software. Please contact Monotype with any
 questions regarding Web Fonts: http://www.fontshop.com
*/

@font-face{
    font-family: "FF Tisa Sans subset";
    src:url("/images/fonts/5560880/7a226ee8-c223-4780-93dc-9dc25d3bdfb8.subset.woff2") format("woff2"),url("/images/fonts/5560880/00373560-75ba-4dcf-ba50-a08e8b066695.subset.woff") format("woff");
}
@font-face{
    font-family: "FF Tisa Sans";
    src:url("/images/fonts/5560880/7a226ee8-c223-4780-93dc-9dc25d3bdfb8.woff2") format("woff2"),url("/images/fonts/5560880/00373560-75ba-4dcf-ba50-a08e8b066695.woff") format("woff");
}
@font-face{
    font-family: "FF Tisa Sans";
    font-style: italic;
    src:url("/images/fonts/5559071/a441fd6b-fbb5-46cf-814c-15ddf05b406f.woff2") format("woff2"),url("/images/fonts/5559071/ac4719fe-2c81-4adc-8de8-ccba3b73bf40.woff") format("woff");
}
@font-face{
    font-family: "FF Tisa Sans";
    font-style: normal;
    font-weight: bold;
    src:url("/images/fonts/5558024/a6f18bbe-5fb4-4dc0-9b5c-6b06a95f45b3.woff2") format("woff2"),url("/images/fonts/5558024/0f3d5935-4c6d-437c-b9d5-ae6f6f7777dc.woff") format("woff");
}
@font-face{
    font-family: "FF Tisa Sans";
    font-style: italic;
    font-weight: bold;
    src:url("/images/fonts/5560886/4e0d79d8-9a0b-4895-a176-97ac0ea075f8.woff2") format("woff2"),url("/images/fonts/5560886/895cf645-00ba-46b6-b4be-69b4368c01b1.woff") format("woff");
}

@font-face{
    font-family: "PT ITC Charter.latin";
    unicode-range: U+000D,U+0020-007E,U+00A0,U+00AD,U+00B7,U+2000-2026,U+FB00-FB04;
    font-display: block;
    src:url("/images/fonts/5682404/6f7f55b5-d511-474f-9652-affbdac0a19e.latin.woff2") format("woff2"),url("/images/fonts/5682404/09d1f64e-8b0e-4a6f-8b1c-eafb48e881ef.latin.woff") format("woff");
}
@font-face{
    font-family: "PT ITC Charter.latin";
    font-style: italic;
    font-display: block;
    unicode-range: U+000D,U+0020-007E,U+00A0,U+00AD,U+00B7,U+2000-2026,U+FB00-FB04;
    src:url("/images/fonts/5687277/089fcb44-dfaf-40ec-96f6-f0aaefdedffc.latin.woff2") format("woff2"),url("/images/fonts/5687277/16509982-13d3-480a-bd9e-96afad3be76f.latin.woff") format("woff");
}

paratype-charter-subset(name) {
    @font-face{
        font-family: name; /* PT */
        src:url("/images/fonts/5682404/6f7f55b5-d511-474f-9652-affbdac0a19e.subset.woff2") format("woff2"),url("/images/fonts/5682404/09d1f64e-8b0e-4a6f-8b1c-eafb48e881ef.subset.woff") format("woff");
    }
}
paratype-charter-subset("ITC Charter subset")
paratype-charter-subset("PT ITC Charter subset")


paratype-charter(name) {
    @font-face{
        font-family: name;
        {block}
        src:url("/images/fonts/5682404/6f7f55b5-d511-474f-9652-affbdac0a19e.woff2") format("woff2"),url("/images/fonts/5682404/09d1f64e-8b0e-4a6f-8b1c-eafb48e881ef.woff") format("woff");
    }
    @font-face{
        font-family: name;
        {block}
        font-style: italic;
        src:url("/images/fonts/5687277/089fcb44-dfaf-40ec-96f6-f0aaefdedffc.woff2") format("woff2"),url("/images/fonts/5687277/16509982-13d3-480a-bd9e-96afad3be76f.woff") format("woff");
    }
    @font-face{
        font-family: name;
        {block}
        font-style: normal;
        font-weight: bold;
        src:url("/images/fonts/5684247/5693b440-d8dc-4db5-8bb4-2bca76e9d58a.woff2") format("woff2"),url("/images/fonts/5684247/dcb46d8a-ec1c-4872-b699-7da176140ce2.woff") format("woff");
    }
    @font-face{
        font-family: name;
        {block}
        font-style: italic;
        font-weight: bold;
        src:url("/images/fonts/5683427/c6f569ad-bff7-4df5-9ce5-43e100bc5e67.woff2") format("woff2"),url("/images/fonts/5683427/9f0f2cb2-6735-47bd-a253-e6924648c369.woff") format("woff");
    }
}
+paratype-charter("PT ITC Charter") {}

itc-charter-regular-and-italic(name) {
    @font-face{
        font-family: name;
        unicode-range: U+0000-03FF,U+0500-1FFFF;
        src:url("/images/fonts/5327262/9eb66b61-093b-4e16-8787-ed765ec564b1.woff2") format("woff2"),url("/images/fonts/5327262/55fe1bef-ac87-457f-80d1-eae9278aeba8.woff") format("woff");
    }
    @font-face{
        font-family: name;
        unicode-range: U+0000-03FF,U+0500-1FFFF;
        font-style: italic;
        src:url("/images/fonts/5179514/58cdaee3-47e9-41f2-acfd-8a2333d1c960.woff2") format("woff2"),url("/images/fonts/5179514/638b5a5a-6a70-4a0a-bb60-bd6009429a5d.woff") format("woff");
    }
}
itc-charter-regular-and-italic("ITC Charter landing")
/* Safari заранее загружает Bold версии шрифтов, так что убираем их в landing */
itc-charter-regular-and-italic("ITC Charter")
@font-face{
    font-family: "ITC Charter";
    unicode-range: U+0000-03FF,U+0500-1FFFF;
    font-style: normal;
    font-weight: bold;
    src:url("/images/fonts/5188095/817bef87-0796-4c3d-84ba-8a4173c34924.woff2") format("woff2"),url("/images/fonts/5188095/03e6cf61-c400-48c7-8b79-ce368ea7bd9d.woff") format("woff");
}
@font-face{
    font-family: "ITC Charter";
    unicode-range: U+0000-03FF,U+0500-1FFFF;
    font-style: italic;
    font-weight: bold;
    src:url("/images/fonts/5345314/84da3d22-0b0c-43d6-a997-380ac5098bad.woff2") format("woff2"),url("/images/fonts/5345314/ba11adcd-4083-45b4-af31-9d55f79247ff.woff") format("woff");
}

+paratype-charter("ITC Charter") {
    unicode-range: U+0400-04FF;
}


@font-face{
    font-family: "FF Kievit.latin";
    unicode-range: U+000D,U+0020-007E,U+00A0,U+00AD,U+00B7,U+00D7,U+2000-2026,U+2212,U+FB00-FB04;
    font-display: block;
    src:url("/images/fonts/5743071/ca33c897-5e7a-42a2-a70d-d134d07d08b6.latin.woff2") format("woff2"),url("/images/fonts/5743071/e9db6430-513a-4a20-a446-746f00cde57c.latin.woff") format("woff");
}
@font-face{
    font-family: "FF Kievit.latin";
    font-style: italic;
    unicode-range: U+000D,U+0020-007E,U+00A0,U+00AD,U+00B7,U+00D7,U+2000-2026,U+2212,U+FB00-FB04;
    font-display: block;
    src:url("/images/fonts/5743080/d2159eda-afdd-451e-a789-e17f49717867.latin.woff2") format("woff2"),url("/images/fonts/5743080/e21c88c6-d767-4b13-b07d-165c9c68cb4c.latin.woff") format("woff");
}
@font-face{
    font-family: "FF Kievit.latin";
    font-style: normal;
    font-weight: bold;
    unicode-range: U+000D,U+0020-007E,U+00A0,U+00AD,U+00B7,U+00D7,U+2000-2026,U+2212,U+FB00-FB04;
    font-display: block;
    src:url("/images/fonts/5742946/b94a1c08-b8bd-4d11-b916-37dfb2073306.latin.woff2") format("woff2"),url("/images/fonts/5742946/c49f1e8d-b0be-4338-bc72-c945abdb4162.latin.woff") format("woff");
}

@font-face{
    font-family: "FF Kievit subset";
    src:/*url("/images/fonts/5743071/ca33c897-5e7a-42a2-a70d-d134d07d08b6.subset.woff2") format("woff2"),
    Chrome жалуется на этот woff2:
    OTS parsing error: Size of decompressed WOFF 2.0 is less than compressed size*/url("/images/fonts/5743071/e9db6430-513a-4a20-a446-746f00cde57c.subset.woff") format("woff");
}
@font-face{
    font-family: "FF Kievit";
/*     unicode-range: U+007F-009F,U+00A1-00AC,U+00AE-00B6,U+00B8-1FFF,U+2027-FAFF; */
    src:url("/images/fonts/5743071/ca33c897-5e7a-42a2-a70d-d134d07d08b6.woff2") format("woff2"),url("/images/fonts/5743071/e9db6430-513a-4a20-a446-746f00cde57c.woff") format("woff");
}
@font-face{
    font-family: "FF Kievit";
    font-style: italic;
    src:url("/images/fonts/5743080/d2159eda-afdd-451e-a789-e17f49717867.woff2") format("woff2"),url("/images/fonts/5743080/e21c88c6-d767-4b13-b07d-165c9c68cb4c.woff") format("woff");
}
@font-face{
    font-family: "FF Kievit";
    font-style: normal;
    font-weight: bold;
    src:url("/images/fonts/5742946/b94a1c08-b8bd-4d11-b916-37dfb2073306.woff2") format("woff2"),url("/images/fonts/5742946/c49f1e8d-b0be-4338-bc72-c945abdb4162.woff") format("woff");
}
@font-face{
    font-family: "FF Kievit";
    font-style: italic;
    font-weight: bold;
    src:url("/images/fonts/5742958/89fdf7e3-5952-4d80-a485-b15be14ca084.woff2") format("woff2"),url("/images/fonts/5742958/ceaaed26-3e29-4463-8e86-f5c4cdabb17e.woff") format("woff");
}


/*!!
 Generated by ParaType (http://www.paratype.com)
 Font Iowan Old Style: Copyright ? ParaType, 2018. All rights reserved.
*/
@font-face {
    font-family: 'PT Iowan Old Style subset';
    src:
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Regular.subset.woff2') format('woff2'),
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Regular.subset.woff') format('woff');
}
@font-face {
    font-family: 'PT Iowan Old Style';
    src:
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Regular.woff2') format('woff2'),
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PT Iowan Old Style';
    src:
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Italic.woff2') format('woff2'),
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-variant-ligatures: no-common-ligatures;
    /* какая-то битая вторая 'f' в ff, ffi
       в bold italic всё в порядке
     */
}
@font-face {
    font-family: 'PT Iowan Old Style';
    src:
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Bold.woff2') format('woff2'),
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'PT Iowan Old Style';
    src:
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Bold%20Italic.woff2') format('woff2'),
	url('/images/fonts/Iowan/Iowan%20Old%20Style_Bold%20Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
